import { AgGridReact, AgGridColumn } from "ag-grid-react";
import React, { Component } from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import XLSX from "xlsx";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';



class CheckPryze extends Component {

	constructor(props)
	 {
		super(props);
		this.state = {
			gametype: 0,
			dpi: "", 
			selectedPlay: null
		};
    }

    
    componentDidMount() {}
	
	componentWillReceiveProps(nextProps) {
		const { userPryzes } = this.props
		 if (nextProps.userPryzes !== userPryzes) {
		   this.setState({ loading: false, userPryzes: userPryzes })
		 }
		}
		
    
	checkPryzes = () => {
		if(this.state.dpi !== "")
		{
			this.setState({loading:true});
			this.props.checkPryze(this.state.dpi, this.props.token);
		}
	};

	
	onRowSelected = event => {
		console.log(event);
		
		if (event.node.selected) {
			console.log(event.data);
			this.setState({
				rowSelected: true, 
				selectedPlay: event.data.idplay
				});

		}else{
			if(this.state.selectedPlay == event.data.idplay)
			{
			this.setState({
				rowSelected: false,
				selectedPlay: null
				});
			}
		}
	  };

	  canjearPryze = () => {
		  if(this.state.selectedPlay)
		  {
			  this.props.canjearPryze(this.state.selectedPlay,this.state.dpi, this.props.token, this.props.idAdminUser);
			  this.setState({loading:true});

		  }
	  }

	render() {
		return (
			<React.Fragment>
			{ this.state.loading
				 ? 
				<Modal
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
						open={this.state.loading}
					>
						<Paper
							style={{
								display: "grid",
								width: "350px",
								padding: "25px",
								margin: "20% auto",
								height: "180px",
								overflowY: "auto"
							}}
						>
							<div style={{ textAlign: "center" }}>
							<br />
							<br />
								<h2><b>Cargando... </b>
								</h2>
							</div>
						</Paper>
					</Modal>
					: null }
			
				<h2 style={{ width: "100%", textAlign: "left" }}>Chequear Premios </h2>
				<br/>
				<TextField
					id="outlined-basic"
								label="Ingrese DPI"
								variant="outlined"
								multiline
								value={this.state.dpi}
								onChange={value =>{
									value.persist();
									this.setState(prevState =>{return { dpi: value.target.value }});
								}}
								style={{ width: "50%", marginRight:"50px" }}
							/>
							 <Button
                                onClick={this.checkPryzes}
                                color="primary"
								variant="contained"
								style={{height: "55px"}}>
                                BUSCAR PREMIOS
                            </Button>
                <br/><br/>
				{
					this.state.selectedPlay !== null ? 
					<React.Fragment>
					<Button onClick={this.canjearPryze}
							color="primary"
							variant="contained"
							style={{height: "55px"}}>
							CANJEAR PREMIO
					</Button>
					<br/><br/>
					</React.Fragment>
					:<React.Fragment><br/><br/><br/><br/></React.Fragment>
					
				}
				<div
					className="ag-theme-balham"
					style={{ height: "75vh", width: "100%" }}
				>
					<AgGridReact
						enableSorting={true}
						enableFilter={true}
						columnDefs={this.state.columnDefs}
						rowData={this.props.userPryzes}
						rowSelection="single"
						onRowSelected={this.onRowSelected}
					>	
					   <AgGridColumn
							headerName="#"
							width={40}
							checkboxSelection
							suppressSorting
							suppressMenu
							suppressFilter
							pinned
							/>
							
						<AgGridColumn headerName="Email" field="email" width={100} />
						<AgGridColumn headerName="Nombre" field="name" width={100} />
						<AgGridColumn headerName="Apellido" field="lastname" width={100} />
						<AgGridColumn headerName="DPI" field="dni" width={100} />
						<AgGridColumn headerName="Premio" field="pryzename" width={100} />
						<AgGridColumn headerName="Descripcion" field="description" width={700} />
						<AgGridColumn headerName="Id" field="idplay" width={20} />

					
					</AgGridReact>
				</div>
               
			</React.Fragment>
		);
	}
}

export default CheckPryze;
