import React, { Component } from "react";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Lock from "@material-ui/icons/Lock";

import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { auth } from "../../store/actions/auth";
import classes from "./Auth.css";
import store from "../../store/store";
import Typography from "@material-ui/core/Typography";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

class Auth extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			showPassword: false
		};
	}

	handleChange = prop => event => {
		this.setState({ ...this.state, [prop]: event.target.value });
		//    setValues({ ...values, [prop]: event.target.value });
	};

	handleClickShowPassword = () => {
		this.setState({ ...this.state, showPassword: !this.state.showPassword });
	};

	submitHandler = () => {
		store.dispatch(auth(this.state.username, this.state.password));
	};

	authDone = () => {
		console.log("AUTH DONE");
	};

	render() {
		return (
			<div>
				<AppBar position="static" style={{ backgroundColor: "#000000" }}>
					<Toolbar>
						<img
							src="img/icon.png"
							style={{ height: "70px", marginLeft: "0" }}
						/>
						<Typography style={{ margin: "0 auto" }} variant="h6">
							Raptor 
						</Typography>
					</Toolbar>
				</AppBar>

				<Paper
					style={{
						display: "grid",
						width: "50%",
						padding: "50px",
						margin: "100px auto"
					}}
				>
					<div style={{ padding: "20px" }}>
						<AccountCircle style={{ display: "block", margin: "0 25%" }} />
						<TextField
							id="input-with-icon-grid"
							label="Nombre de Usuario"
							onChange={this.handleChange("username")}
							style={{
								width: "50%"
							}}
						/>
					</div>
					<div style={{ padding: "20px" }}>
						<Lock style={{ display: "block", margin: "0 25%" }} />
						<TextField
							id="input-with-icon-grid"
							label="Password"
							type={this.state.showPassword ? "text" : "password"}
							className={classes.TextF}
							style={{
								width: "50%"
							}}
							onChange={this.handleChange("password")}
							InputProps={{
								endAdornment: (
									<InputAdornment variant="filled" position="end">
										<IconButton
											aria-label="Toggle password visibility"
											onClick={this.handleClickShowPassword}
										>
											{this.state.showPassword ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</div>
					{this.props.error !== null ? (
						<Typography variant="h7">{this.props.error}</Typography>
					) : null}
					<div style={{ padding: "20px" }}>
						<Button
							label="Submit"
							variant="contained"
							color="primary"
							onClick={this.submitHandler}
							style={{
								height: "50px",
								width: "35%",
								marginTop: "20px",
								fontSize: "1em",
								alignContent: "center",
								margin: "0 auto",
								backgroundColor: "#7C9EB2"
							}}
						>
							Login
						</Button>
					</div>
				</Paper>
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		isAuthenticated: state.authReducer.isAuthenticated,
		token: state.authReducer.token,
		error: state.authReducer.error
	};
};

export default connect(mapStateToProps)(Auth);
