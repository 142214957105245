import React, { Component } from "react";
import { connect } from "react-redux";

import Manager from "../../containers/Manager/Manager";
import Auth from "../Auth/Auth";
import { authCheckState } from "../../store/actions/auth";

import store from "../../store/store";

let currentValue = 0;
class Backend extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAuthenticated: false
		};
	}
	componentDidMount() {
		store.dispatch(authCheckState());
		this.setState = {
			isAuthenticated: store.getState().authReducer.isAuthenticated
		};
		var unsubscribe = store.subscribe(this.handleChange);
		unsubscribe();
	}

	handleChange = () => {
		let previousValue = currentValue;
		currentValue = store.getState().authReducer.isAuthenticated;
	};

	OnLogin = () => {
		/*console.log(store.getState().authReducer.isAuthenticated);
    const isA = store.getState().authReducer.isAuthenticated;
    this.setState({
      isAuthenticated: isA
    });
    console.log(this.state.isAuthenticated);*/
	};

	render() {
		return (
			<React.Fragment>
				{this.props.isAuthenticated === true ? <Manager /> : <Auth />}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.authReducer.isAuthenticated,
		token: state.authReducer.token
	};
};

export default connect(mapStateToProps)(Backend);
