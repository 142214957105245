import React, { Component } from "react";
import { connect } from "react-redux";

import store from "../../store/store";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import * as raptorActions from "../../store/actions/Index";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Game from "../Game/Game";
import Register from "../Register/Register";

import { authUserCheckState } from "../../store/actions/UsersAction";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
let currentValue = 0;

class RegisterGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRegistered: false
    };
  }

  componentDidMount() {
    store.dispatch(authUserCheckState());
    this.setState = {
      isRegistered: store.getState().authReducer.isAuthenticated
    };
    var unsubscribe = store.subscribe(this.handleChange);
    unsubscribe();
  }

  handleChange = () => {
    let previousValue = currentValue;
    currentValue = store.getState().authReducer.isRegistered;
  };

  useStyles = () =>
    makeStyles(theme => ({
      button: {
        margin: 0
      },
      input: {
        display: "none"
      }
    }));

  render() {
    const classes = this.useStyles();
    return this.props.isRegistered == true ? <Game userName={this.props.userName} userId={this.props.userId} loc={this.props.match.params.loc }/> : <Register loc={this.props.match.params.loc }/>;
  }
}

const mapStateToProps = state => {
  return {
    isRegistered: state.reducer.isRegistered,
    userName: state.reducer.userName,
    userId: state.reducer.userId

  };
};

const mapDispatchToProps = dispatch => {
	return {
    handleRegister: (user) => dispatch(raptorActions.registerUser(user))
	
	};
};
export default connect(mapStateToProps)(RegisterGame);
