import { createStore, combineReducers, applyMiddleware, compose } from "redux";

import { logger } from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";

import authReducer from "../store/reducers/authReducer";
import reducer from "../store/reducers/reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  combineReducers({
    reducer,
    authReducer
  }),
  {},
  composeEnhancers(applyMiddleware(logger, thunk, promise))
);
