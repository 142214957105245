import * as ActionTypes from "./ActionTypes";
import axios from "axios";

export const setUsers = users => {
	return {
		type: ActionTypes.LOAD_USERS,
		users: users
	};
};

export const setAdmin = adminUsers => {
	return {
		type: ActionTypes.LOAD_ADMIN,
		adminUsers: adminUsers
	};
};




export const setCheckPryzes = pryzes => {
	return {
		type: ActionTypes.SET_PRYZE,
		pryzes: pryzes
	};
};

export const setSucursales = sucursales => {
	return {
		type: ActionTypes.SET_SUCURSALES,
		sucursales: sucursales
	}
}

export const finishGame = (userId, range, idlocation) => {

	return dispatch => {
		
		axios
			.post(
				"http://138.197.231.38/users/pryzegamesolo",
				{"iduser":userId, "range":range, "idlocation":idlocation}
			)				
			.then(response => {
				if (response != undefined) {
					var pryze = response.data;
					dispatch(setPryze(pryze));
				}
			})
			.catch(error => {});
	};

}

export const setPryze = pryze => {
	return {
		type: ActionTypes.SET_PRYZE,
		pryze: pryze
	}
}

export const loadUsers = (token, country) => {
	return dispatch => {
		const AuthStr = "Bearer ".concat(token);

		axios
			.get(
				"http://138.197.231.38/users/getplayers/" + country,
				{
					headers: { Authorization: AuthStr }
				}
			)
			.then(response => {
				if (response != undefined) {
					var users = response.data;
					dispatch(setUsers(users));
				}
			})
			.catch(error => {});
	};
};

export const checkPryze = (dpi, token) => {
	return dispatch => {
		const AuthStr = "Bearer ".concat(token);

		axios
			.get(
				"http://138.197.231.38/users/check/" + dpi,
				
				{
					headers: { Authorization: AuthStr }
				}
			)
			.then(response => {
				if (response != undefined) 
				{
					console.log(response);
					dispatch(setCheckPryzes(response.data));
					}
			})
			.catch(error => {});
	};
}


export const canjearPryze = (idplay, dpi, token, idAdminUser) => {
	return dispatch => {
		const AuthStr = "Bearer ".concat(token);

		axios
			.post(
				"http://138.197.231.38/users/canjear/" ,
				{"idplay":idplay, "idadminuser":idAdminUser},
				{
					headers: { Authorization: AuthStr }
				}
			)
			.then(response => {
				if (response != undefined) 
				{
					console.log(response);
					dispatch(checkPryze(dpi, token));
					}
			})
			.catch(error => {});
	};
}



export const registerUser = (email, name, lastname, phone, dni, location) => {
	return dispatch => {
		axios
			.post(
				"http://138.197.231.38/users/registerweb/" ,
				{"email":email, "name":name, "lastname":lastname, "phone":phone, "dni": dni, "idregion": location}
			)
			.then(response => {
				if (response != undefined) 
				{
					console.log(response);
					if (response.data.error == 0) {
						dispatch(registerSuccess(response.data.iduser, name));
					  } else {

						dispatch(authFail({ error: "Usuario "}));
			  
						//this.setState({ error: "Usuario/Contraseña incorrectas" });
					  }
					}
			})
			.catch(error => {});
	};
}



export const loadPryzesGame = (token, country) => {
	return dispatch => {
		axios
			.get(
				"http://138.197.231.38/pryzes/gamestatics/" + country
			)
			.then(response => {
				if (response != undefined) 
				{
					console.log(response);
					if (response.data != null) {
						dispatch(setPryzesGame(response.data));
					  } else {

						//dispatch(authFail({ error: "Usuario "}));
			  
						//this.setState({ error: "Usuario/Contraseña incorrectas" });
					  }
					}
			})
			.catch(error => {});
	};
}


export const loadPryzesGameJackpot = (token, country) => {
	return dispatch => {
		axios
			.get(
				"http://138.197.231.38/pryzes/gamestaticsjackpot/" + country
			)
			.then(response => {
			
					console.log(response);
					if (response.data != null) {
						dispatch(setPryzesJackpotGame(response.data));
					  } else {
						//dispatch(authFail({ error: "Usuario "}));
						//this.setState({ error: "Usuario/Contraseña incorrectas" });
					  }
			})
			.catch(error => {});
	};
}


export const editPryze = (token, pryze, country) => {
	return dispatch => {
		const AuthStr = "Bearer ".concat(token);
		axios
			.post(
				"http://138.197.231.38/pryzes/edit/" ,
				{ "id1": pryze.id1, "disp1": pryze.disp1,"id2": pryze.id2, "disp2": pryze.disp2,"id3": pryze.id3, "disp3": pryze.disp3,"id4": pryze.id4, "disp4": pryze.disp4},
				{
					headers: { Authorization: AuthStr }
				}
			)
			.then(response => {
					console.log(response);
						dispatch(loadPryzesGame(token, country));
						dispatch(loadPryzesGameJackpot(token, country));
			})
			.catch(error => {});
	};
}




export const loadPryzesDelyver = (token, country) => {
	return dispatch => {
		axios
			.get(
				"http://138.197.231.38/pryzes/gamedelyver/"+country
			)
			.then(response => {
				if (response != undefined)
				{
					console.log(response);
					if (response.data != null) {
						dispatch(setPryzesDelyver(response.data));
					  } else {

						//dispatch(authFail({ error: "Usuario "}));
						//this.setState({ error: "Usuario/Contraseña incorrectas" });
					  }
					}
			})
			.catch(error => {});
	};
}

export const setPryzesGame = pryzes => {
	return {
		type: ActionTypes.SET_PRYZES_GAME,
		pryzes: pryzes
	}
}

export const setPryzesJackpotGame = pryzes => {
	return {
		type: ActionTypes.SET_PRYZES_JACKPOT,
		pryzesJackpot: pryzes
	}
}

export const setPryzesDelyver = pryzes => {
	return {
		type: ActionTypes.SET_PRYZES_DELYVER,
		pryzesDelyver: pryzes
	}
}

export const registerSuccess = (userId, name) => {

	const expirationDate = new Date(new Date().getTime() + 1 * 1000);
	localStorage.setItem("raptorUserIdUser", userId);
	localStorage.setItem("raptorUserName", name);
	localStorage.setItem("raptorUserExpirationDate", expirationDate);

	return {
	  type: ActionTypes.REGISTER_USER_SUCCESS,
	  userId: userId, 
	  userName: name
	};
  };

  export const authFail = (error) => {
	return {
	  type: ActionTypes.REGISTER_ERROR,
	  error: error
	};
  };

  

  export const authUserCheckState = () => {
	return function(dispatch) {
		const userId = localStorage.getItem("raptorUserIdUser");
		if (!userId) {
			return dispatch(authUserLogOut());
		} else {
			const expirationDate = new Date(
				localStorage.getItem("raptorUserExpirationDate")
			);
			if (expirationDate > new Date()) {
				checkAuthTimeOut(
					(expirationDate.getTime() - new Date().getTime()) / 1000
				);
				return dispatch(
					registerSuccess(userId, expirationDate.getTime() - new Date().getTime())
				);
			} else {
				return dispatch(authUserLogOut());
			}
		}
	};
};


const checkAuthTimeOut = expirationTime => {
	return dispatch => {
		setTimeout(() => {
			dispatch(authUserLogOut());
		}, expirationTime * 1000);
	};
};


export const authUserLogOut = () => {
	localStorage.setItem("raptorUserIdUser", "");
	return {
		type: ActionTypes.REGISTER_USER_LOGOUT,
		userId: null,
		expirationDate: null,
		isAuthenticated: false
	};
};


export const loadAdmin = token => {
	return dispatch => {
		const AuthStr = "Bearer ".concat(token);

		axios
			.get(
				"http://138.197.231.38/users/getAdmin",
				{
					headers: { Authorization: AuthStr }
				}
			)
			.then(response => {
				if (response != undefined) {
					var adminUsers = response.data;
					dispatch(setAdmin(adminUsers));
				}
			})
			.catch(error => {});
	};
};

export const loadSucursales = token => {
	return dispatch => {
		const AuthStr = "Bearer ".concat(token);

		axios
			.get(
				"http://138.197.231.38/users/sucursales",
				{
					headers: { Authorization: AuthStr }
				}
			)
			.then(response => {
				if (response != undefined) {
					var sucursales = response.data;
					dispatch(setSucursales(sucursales));
				}
			})
			.catch(error => {});
	};
};


export const createAdmin = (token, admin) => {
	return dispatch => {
		const AuthStr = "Bearer ".concat(token);

		axios
			.post(
				"http://138.197.231.38/login/signupadmin",
				{admin},
				{
					headers: { Authorization: AuthStr }
				}
			)
			.then(response => {
				if (response != undefined) {
					dispatch(loadAdmin(token));
				}
			})
			.catch(error => {});
	};
};


export const deleteAdmin = (token, userIdAdmin) => {
	return dispatch => {
		const AuthStr = "Bearer ".concat(token);

		axios
			.post(
				"http://138.197.231.38/users/deleteadmin",
				{"id":userIdAdmin},
				{
					headers: { Authorization: AuthStr }
				}
			)
			.then(response => {
				if (response != undefined) {
					dispatch(loadAdmin(token));
				}
			})
			.catch(error => {});
	};
};


