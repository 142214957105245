import { AgGridReact, AgGridColumn } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import XLSX from "xlsx";
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';

import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';


class Admin extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			current:"general",
			username: "",
			description:"",
			userpassword:"",
			sucursal:"",
			createNew: false
		};
	}

	
	onRowSelected = event => {
		console.log(event);
		
		if (event.node.selected) {
			console.log(event.data);
			this.setState({
				rowSelected: true, 
				selectedUser: event.data.idadminuser
				});

		}else{
			if(this.state.selectedUser == event.data.idadminuser)
			{
			this.setState({
				rowSelected: false,
				selectedUser: null
				});
			}
		}
	  };

      
	handleDelete = () => {
		this.props.deleteUserAdmin(this.props.token, this.state.selectedUser);
		this.setState({
			deleteModal:false
		});
	}



	exportFile = () => {
		/* convert state to workbook */
		let cols = this.props.users.map((info, i) => {
			return [
				info.nombre,
				info.apellido,
				info.email,
				info.xp,
				info.level, 
				info.totalanswers,
				info.correctanswers,
				info.totaltime, 
				info.averagetime,
				info.averagexp
			];
		});
		let hcols = [
			"Nombre",
			"Apellido",
			"E-mail",
			"XP",
			"Nivel",
			"Total Respuestas",
			"Respuestas Correctas",
			"Tiempo Total",
			"Tiempo Promedio",
			"XP Promedio"
		];

		const exp = [[...hcols], ...cols];
		const ws = XLSX.utils.aoa_to_sheet(exp);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Usuarios");
		/* generate XLSX file and send to client */
		XLSX.writeFile(wb, "Usuarios.xlsx");
	};

	handleDelete = () => {
		this.props.deleteUserAdmin(this.props.token, this.state.selectedUser);
		this.setState({
            deleteModal:false,
            selectedUser:""
		});
	}

    createUsuario = () => {
        if(
            this.state.username == "" ||
            this.state.userpassword == "" ||
            this.state.description == "" 
        )
        {
            return;
        }else{
        this.props.createUserAdmin(this.props.token, 
            { "user":this.state.username, "password":this.state.userpassword, "description":this.state.description});
        this.setState({createNew: false, username:"", userpassword:"", description:""});

        }
    }

	componentDidMount() {

	}

	render() {
		return (
			<React.Fragment>
			{ this.state.deleteModal ? 
            <Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={this.state.deleteModal}
				>
					<Paper
						style={{
							display: "grid",
							width: "500px",
							padding: "25px",
                            margin: "20% auto",
                            height: "200px",
                            overflowY: "auto"
						}}
					>
						<div style={{ textAlign: "center" }}>
						<br />
						<br />
							<b>Seguro desea borrar? </b>
							<br /><br /><br />
                    
								<Button
								onClick={this.handleDelete}
								type="submit"
								color="primary"
								variant="contained"
							>
								Borrar
							</Button>
						
							
							&nbsp;&nbsp;&nbsp;
							<Button onClick={()=>{this.setState({deleteModal:false})}} color="primary">
								Cancelar
							</Button>
						
						</div>
					</Paper>
				</Modal>
				: null }


			<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={this.state.createNew}
				>
					<Paper
						style={{
							display: "grid",
							width: "60%",
							padding: "25px",
                            margin: "auto auto",
                            height: "58%",
                            overflowY: "auto"
						}}
					>
						<div style={{ textAlign: "center" }}>
							<h2>Crear Usuario</h2>
							<br />
                            <TextField
								id="standard-select"
								label="username"
								variant="outlined"
								style={{
									marginBottom: "20px",
									width: "90%",
									textAlign: "left"
								}}
								value={this.state.username}
								onChange={value =>{this.setState({ username: value.target.value });}}
							>
							</TextField>
							<br />
							<TextField
								id="standard-select"
								label="Password"
								variant="outlined"
								style={{
									marginBottom: "20px",
									width: "90%",
									textAlign: "left"
								}}
								value={this.state.userpassword}
								onChange={value =>{this.setState({ userpassword: value.target.value });}}
							>
							</TextField>
							<br />
                            <TextField
								id="standard-select"
								label="Descripción"
								variant="outlined"
								style={{
									marginBottom: "20px",
                                 	width: "90%",
									textAlign: "left"
								}}
								value={this.state.description}
								onChange={value =>{this.setState({ description: value.target.value });}}
							>
							</TextField>
							<br />
							
                            <TextField
								id="standard-select"
								select
								label="Sucursal"
								style={{
                                 
									width: "90%",
									textAlign: "left"
								}}
								value={this.state.sucursal}
								onChange={value =>{this.setState({ sucursal: value.target.value });}}

							>
								{this.props.sucursales.map(option => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
							<br />
							<br />
							<Button onClick={this.createUsuario} type="submit" color="primary" variant="contained" >
								Confirmar
							</Button>
							&nbsp;&nbsp;&nbsp;
							<Button onClick={()=>{this.setState({createNew: false});}} color="primary">
								Cancelar
							</Button>
							<br />
						</div>
					</Paper>
				</Modal>




				<h2 style={{ width: "100%", textAlign: "left" }}>Usuarios</h2>
				<div style={{ width: "50%", textAlign: "left", marginBottom: "20px" }}>
					{" "}
					
					{"  "}
					<Button
						onClick={()=>{this.setState({createNew: true});}}
						color="primary"
						variant="contained"
					>
						Crear Usuario Admin
					</Button>
					{" "}
					{ this.state.rowSelected ? 
					<Button
						onClick={()=>{this.setState({deleteModal: true})}}
						color="primary"
						variant="contained"
					>
						Borrar
					</Button>
					:null}
                    <div style={{ float: "right" }}>

                <Button
                        onClick={this.exportFile}
                        color="primary"
                        variant="contained"
                    >
                        Exportar a Excel
                    </Button>
                </div>
				</div>
				<div style={{ width: "50%", textAlign: "left", marginBottom: "20px" }}>
					
				</div>
				<div
					className="ag-theme-balham"
					style={{ height: "80vh", width: "50%" }}
				>
					<AgGridReact
						enableSorting={true}
						enableFilter={true}
						//columnDefs={this.state.columnDefs}
						rowData={this.props.adminUsers  ? this.props.adminUsers : null}
						rowSelection="single"
						onRowSelected={this.onRowSelected}
						onSelectionChanged={this.onSelectionChanged}
					>
					<AgGridColumn
					headerName="#"
					width={40}
					checkboxSelection
					suppressSorting
					suppressMenu
					suppressFilter
					pinned
					/>
						<AgGridColumn headerName="Username" field="username" width={100} />
						<AgGridColumn headerName="Descripción" field="description" width={200} />
						<AgGridColumn headerName="Id" field="idadminuser" width={20} />

					</AgGridReact>
				</div>
			</React.Fragment>
		);
	}
}

export default Admin;
