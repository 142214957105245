import { AgGridReact, AgGridColumn } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import XLSX from "xlsx";
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';

import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";

import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';


class PryzesJackpot extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			current:"general",
			username: "",
			userid: "",
			userlastname:"",
			useremail:"",
			userage:"",
			userpassword:"",
			id1:null,
			id2:null,
			id3:null,
			id4:null
			
		};
	}

	
	onRowSelected = event => {
		console.log(event);
		
		if (event.node.selected) {
			console.log(event.data);
			this.setState({
				rowSelected: true, 
				id1: event.data.id1,
				olddisp1:event.data.disponibleequipo1,
				disp1:event.data.disponibleequipo1,
				id2: event.data.id2,
				disp2:event.data.disponibleequipo2,
				olddisp2:event.data.disponibleequipo2,
				id3: event.data.id3,
				disp3:event.data.disponibleequipo3,
				olddisp3:event.data.disponibleequipo3,
				id4: event.data.id4,
				disp4:event.data.disponibleequipo4,
				olddisp4:event.data.disponibleequipo4,
				selectedPryze:event.data.name
				});

		}else{
			if(this.state.id1 == event.data.id1)
			{
			this.setState({
				rowSelected: false,
				id1: null,
				disp1:0,
				olddisp1:0,
				id2: null,
				disp2:0,
				olddisp1:0,
				id3: null,
				disp3:0,
				olddisp3:0,
				id4: null,
				disp4:0,
				olddisp4:0,
				});
			}
		}
	  };



	exportFile = () => {
		/* convert state to workbook */
		let cols = this.props.pryzesGameList.map((info, i) => {
			return [
				info.name,
				info.totalequipo1,
				info.disponibleequipo1,
				info.totalequipo2,
				info.disponibleequipo2, 
				info.totalequipo3, 
				info.disponibleequipo3,
				info.totalequipo4,
				info.disponibleequipo4,
				info.nivel, 
				info.totalpremios
			];
		});
		let hcols = [
			"Nombre",
			"Total Equipo 1",
			"Disponible Equipo 1",
			"Total Equipo 2",
			"Disponible Equipo 2",
			"Total Equipo 3",
			"Disponible Equipo 3",
			"Total Equipo 4",
			"Disponible Equipo 4",
			"Nivel",
			"Total Premios"
			];

		const exp = [[...hcols], ...cols];
		const ws = XLSX.utils.aoa_to_sheet(exp);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "PremiosEstadisticas");
		/* generate XLSX file and send to client */
		XLSX.writeFile(wb, "PremiosEstadisticas.xlsx");
	};




	exportFile2 = () => {
		/* convert state to workbook */
		let cols = this.props.pryzesDelyver.map((info, i) => {
			return [
				info.name,
				info.fecha,
				info.location,
				info.entregados,
				info.disponibleequipo2, 
				info.totalequipo3, 
				info.disponibleequipo3,
				info.totalequipo4,
				info.disponibleequipo4,
				info.nivel, 
				info.totalpremios
			];
		});
		let hcols = [
			"Nombre Premio",
			"Fecha",
			"Equipo",
			"Premios Entregados"
			];

		const exp = [[...hcols], ...cols];
		const ws = XLSX.utils.aoa_to_sheet(exp);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "PremiosEntregados");
		/* generate XLSX file and send to client */
		XLSX.writeFile(wb, "PremiosEntregados.xlsx");
	};



	handleEdit = () => {
		this.props.editPryze(this.props.token, 
							{
								id1:this.state.id1,
								disp1:  this.state.olddisp1 - this.state.disp1,
								id2:this.state.id2,
								disp2: this.state.olddisp2 - this.state.disp2,
								id3:this.state.id3,
								disp3: this.state.olddisp3 - this.state.disp3,
								id4:this.state.id4,
								disp4: this.state.olddisp4 - this.state.disp4
							}, this.props.country);
		this.setState({
			editModal:false
		});
		this.setState({
			rowSelected: false,
			id1: null,
			disp1:0,
			olddisp1:0,
			id2: null,
			disp2:0,
			olddisp1:0,
			id3: null,
			disp3:0,
			olddisp3:0,
			id4: null,
			disp4:0,
			olddisp4:0,
			});
	}


	componentDidMount() {

	}

	render() {
		return (
			
			<React.Fragment>
				{ this.state.editModal ? 
            <Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={this.state.editModal}
				>
					<Paper
						style={{
							display: "grid",
							width: "500px",
							padding: "25px",
                            margin: "20% auto",
                            height: "600px",
                            overflowY: "auto"
						}}
					>
						<div style={{ textAlign: "center" }}>
						<br />
						<br />
							<b>Editar Premio {this.state.selectedPryze}</b>
							<br />
							<br />
                            <TextField
								id="standard-select"
								label="Disponible Grupo 1"
								variant="outlined"
								style={{
									marginBottom: "20px",
									width: "90%",
									textAlign: "left"
								}}
								value={this.state.disp1}
								onChange={value =>{this.setState({ disp1: value.target.value });}}
							>
							</TextField>
							<TextField
								id="standard-select"
								label="Disponible Grupo 2"
								variant="outlined"
								style={{
									marginBottom: "20px",
									width: "90%",
									textAlign: "left"
								}}
								value={this.state.disp2}
								onChange={value =>{this.setState({ disp2: value.target.value });}}
							>
							</TextField>
							<TextField
								id="standard-select"
								label="Disponible Grupo 3"
								variant="outlined"
								style={{
									marginBottom: "20px",
									width: "90%",
									textAlign: "left"
								}}
								value={this.state.disp3}
								onChange={value =>{this.setState({ disp3: value.target.value });}}
							>
							</TextField>

							<br /><br /><br />
								<Button
								onClick={this.handleEdit}
								type="submit"
								color="primary"
								variant="contained"
							>
								Guardar
							</Button>
							&nbsp;&nbsp;&nbsp;
							<Button onClick={()=>{this.setState({editModal:false})}} color="primary">
								Cancelar
							</Button>
						</div>
					</Paper>
				</Modal>
				: null }
				<h2 style={{ width: "100%", textAlign: "left" }}>Premios Juego Ruleta</h2>
				<div style={{ width: "100%", textAlign: "left", marginBottom: "20px" }}>
					<div style={{ float: "right" }}>
					{ this.state.rowSelected ? 
					<Button
						onClick={()=>{this.setState({editModal: true})}}
						color="primary"
						variant="contained"
					>
						Editar
					</Button>
					:null}
					<br/><br/>
					<Button
							onClick={this.exportFile}
							color="primary"
							variant="contained"
						>
							Exportar a Excel
						</Button>
					</div>
				</div>
				<div
					className="ag-theme-balham"
					style={{ height: "40vh", width: "100%" }}
				>
					<AgGridReact
						enableSorting={true}
						enableFilter={true}
						columnDefs={this.state.columnDefs}
						rowData={this.props.pryzesGameList  ? this.props.pryzesGameList : null}
						rowSelection="single"
						onRowSelected={this.onRowSelected}
						onSelectionChanged={this.onSelectionChanged}
					>
						<AgGridColumn
					headerName="#"
					width={40}
					checkboxSelection
					suppressSorting
					suppressMenu
					suppressFilter
					pinned
					/>
						<AgGridColumn headerName="Nombre" field="name" width={200} />
						<AgGridColumn headerName="Total Equipo 1" field="totalequipo1" width={125} />
						<AgGridColumn headerName="Disponible Equipo 1" field="disponibleequipo1" width={175} />
						<AgGridColumn headerName="Total Equipo 2" field="totalequipo2" width={125} />
						<AgGridColumn headerName="Disponible Equipo 2" field="disponibleequipo2" width={175} />
						<AgGridColumn headerName="Total Equipo 3" field="totalequipo3" width={125} />
						<AgGridColumn headerName="Disponible Equipo 3" field="disponibleequipo3" width={175} />
						<AgGridColumn headerName="Total Premios" field="totalpremios" width={150} />
						<AgGridColumn headerName="id1" field="id1" width={1} />
						<AgGridColumn headerName="id2" field="id2" width={1} />
						<AgGridColumn headerName="id3" field="id3" width={1} />


					</AgGridReact>
				</div>

				<h2 style={{ width: "100%", textAlign: "left" }}>Premios Entregados</h2>
				<div style={{ width: "100%", textAlign: "left", marginBottom: "20px" }}>
					<div style={{ float: "right" }}>

					<Button
							onClick={this.exportFile2}
							color="primary"
							variant="contained"
						>
							Exportar a Excel
						</Button>
					</div>
				</div>
				<div
					className="ag-theme-balham"
					style={{ height: "80vh", width: "100%" }}
				>
					<AgGridReact
						enableSorting={true}
						enableFilter={true}
						columnDefs={this.state.columnDefs}
						rowData={this.props.pryzesDelyver  ? this.props.pryzesDelyver : null}
						rowSelection="single"
						onRowSelected={this.onRowSelected}
						onSelectionChanged={this.onSelectionChanged}
					>
						<AgGridColumn headerName="Nombre" field="name" width={200} />
						<AgGridColumn headerName="Fecha" field="fecha" width={125} />
						<AgGridColumn headerName="Equipo" field="location" width={175} />
						<AgGridColumn headerName="Total Entregados" field="entregados" width={125} />

					</AgGridReact>
				</div>
			</React.Fragment>
		);
	}
}

export default PryzesJackpot;
