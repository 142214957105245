import * as ActionTypes from "../actions/ActionTypes";

const initialState = {
	favourites: [{}],
	tourismEvents: [],
	informativeTexts: [],
	sucursales:[],
	pryzesGameList: [],
	pryzesGameDelyver:[],
	pryzesGameJackpot: [],
	
	checkPryzeMenuOpen: false,
	usuariosMenuOpen: true,
	sideMenuOpen: false,
	formMenuOpen: false,
	textMenuOpen: false,
	modifyFormMenuOpen: false,
	questionsMenuOpen: false,
	topquestionsMenuOpen: false,
	pryzeMenuOpen: false,

	listMenuOpen: false,
	updateListMenu: false,
	uploadingImage: false,
	imageUrlResponse: "",
	deletingElement: false,
	creatingEvent: false,
	modifyingElement: false,
	modifyingInformativeText: false,
	eventToModify: {
		id: 0,
		type: "",
		titulo: "",
		info: "",
		link: "",
		imageUrl: ""
	},
	event: {
		imageUrl: ""
	},
	textToModify: {
		id: 0,
		type: "",
		title: "",
		info: ""
	},
	levels:[],
	levelCategories:[]
};

const reducer = (state = initialState, action) => {
	switch (action.type) {

		case ActionTypes.LOAD_USERS:
			return {
				...state,
				users: action.users
			};

		case ActionTypes.LOAD_ADMIN:
			return {
				...state,
				adminUsers: action.adminUsers
			};
	

		case ActionTypes.LOAD_PRYZES:
			return{
				...state,
				pryzes: action.pryzes
			}

		
		case ActionTypes.LOAD_SUCURSALES:
			return{
				...state,
				sucursales: action.sucursales
			}
		
		case ActionTypes.OPENCLOSE_SIDEMENU:
			return {
				...state,
				sideMenuOpen: !state.sideMenuOpen
			};
		case ActionTypes.OPEN_FORMMENU:
			return {
				...state,
				sideMenuOpen: false,
				listMenuOpen: false,
				textMenuOpen: false,
				formMenuOpen: true,
				principalMenuOpen: false,
				favouritesMenuOpen: false,
				pryzeMenuOpen: false,
				pryzeJackpotMenuOpen: false

			};

		case ActionTypes.OPEN_USUARIOS:
			return {
				...state,
				sideMenuOpen: false,
				usuariosMenuOpen: true,
				checkPryzeMenuOpen: false,
				adminMenuOpen: false,
				pryzeMenuOpen: false,
				pryzeJackpotMenuOpen: false


			};

		case ActionTypes.OPEN_CHECK:
			return {
				...state,
				sideMenuOpen: false,
				usuariosMenuOpen: false,
				checkPryzeMenuOpen: true,
				adminMenuOpen: false,
				pryzeMenuOpen: false,
				pryzeJackpotMenuOpen: false


			};
		case ActionTypes.OPEN_PRYZES:
				return {
					...state,
					sideMenuOpen: false,
					usuariosMenuOpen: false,
					checkPryzeMenuOpen: false,
					adminMenuOpen: false,
					pryzeMenuOpen: true,
					pryzeJackpotMenuOpen: false

	
				};
			
		case ActionTypes.OPEN_PRYZES_JACKPOT:
			return {
				...state,
				sideMenuOpen: false,
				usuariosMenuOpen: false,
				checkPryzeMenuOpen: false,
				adminMenuOpen: false,
				pryzeMenuOpen: false,
				pryzeJackpotMenuOpen: true

			};
		case ActionTypes.OPEN_ADMIN:
			return {
				...state,
				sideMenuOpen: false,
				usuariosMenuOpen: false,
				checkPryzeMenuOpen: false,
				adminMenuOpen: true,
				pryzeMenuOpen: false,
				pryzeJackpotMenuOpen: false


			};


		case ActionTypes.UPLOADING_IMAGE:
			return {
				...state,
				uploadingImage: true,
				event: {
					...state.event,
					imageUrl: action.imageUrl
				}
			};

			case ActionTypes.REGISTER_USER_SUCCESS:
				return (state = {
					...state,
					userId: action.userId,
					userName: action.userName,
					expirationDate: action.expirationDate,
					isRegistered: true,
					pryze:""
				});
			case ActionTypes.REGISTER_ERROR:
					return (state = {
						...state,
						error: action.error
					});
			case ActionTypes.REGISTER_USER_LOGOUT:
			return (state = {
				...state,
				userId: null,
				expirationDate: null,
				isRegistered: false,
				error: null
			});
			case ActionTypes.SET_PRYZE:
				return (state = {
					...state,
					pryze: action.pryze
				});
			case ActionTypes.SET_PRYZES_GAME:
				return (state = {
					...state,
					pryzesGameList: action.pryzes
				});
			case ActionTypes.SET_PRYZES_JACKPOT:
				return (state = {
					...state,
					pryzesJackpot: action.pryzesJackpot
				});
			case ActionTypes.SET_PRYZES_DELYVER:
					return (state = {
						...state,
						pryzesGameDelyver: action.pryzesDelyver
					});
			case ActionTypes.SET_SUCURSALES:
					return (state = {
						...state,
						sucursales: action.sucursales
			});
	

		default:
			return state;
	}
};

export default reducer;
