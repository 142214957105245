const axios = require("axios");

export const AUTH_START = "AUTH_START";

export const authStart = () => {
	return {
		type: AUTH_START
	};
};

export const AUTH_SUCCESS = "AUTH_SUCCESS";

export const authSuccess = (token, expiresIn, superAdmin, idAdminUser, country) => {
	const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
	localStorage.setItem("raptorToken2", token);
	localStorage.setItem("raptorExpirationDate", expirationDate);
	localStorage.setItem("superRaptor", superAdmin);
	localStorage.setItem("idAdminUser", idAdminUser);
	localStorage.setItem("idCountryRaptor", country);

	return {
		type: AUTH_SUCCESS,
		token: token,
		expirationDate: expirationDate,
		superAdmin: superAdmin, 
		idAdminUser: idAdminUser,
		country: country
	};
};
export const AUTH_FAIL = "AUTH_FAIL";

export const authFail = data => {
	return {
		type: AUTH_FAIL,
		error: data.error
	};
};

export const authCheckState = () => {
	return function(dispatch) {
		const token = localStorage.getItem("raptorToken2");
		const superRaptor = localStorage.getItem("superRaptor");
		const idAdminUser = localStorage.getItem("idAdminUser");
		const country = localStorage.getItem("idCountryRaptor");

		if (!token) {
			return dispatch(authLogOut());
		} else {
			const expirationDate = new Date(
				localStorage.getItem("raptorExpirationDate")
			);
			if (expirationDate > new Date()) {
				checkAuthTimeOut(
					(expirationDate.getTime() - new Date().getTime()) / 1000
				);
				return dispatch(
					authSuccess(token, expirationDate.getTime() - new Date().getTime(), superRaptor, idAdminUser, country)
				);
			} else {
				return dispatch(authLogOut());
			}
		}
	};
};

export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const authLogOut = () => {
	localStorage.setItem("raptorToken2", "");
	return {
		type: AUTH_LOGOUT,
		token: null,
		expirationDate: null,
		isUserAuthenticated: false
	};
};

const checkAuthTimeOut = expirationTime => {
	return dispatch => {
		setTimeout(() => {
			dispatch(authLogOut());
		}, expirationTime * 1000);
	};
};

export const auth = (email, password) => {
	return dispatch => {
		dispatch(authStart());
		return axios
			.post("http://138.197.231.38/login/admin", {
				user: email,
				password: password
			})
			.then(response => {
				if (response.data.error === 0) {
					dispatch(authSuccess(response.data.token, response.data.expiresIn, response.data.super, response.data.iduser, response.data.country));
				} else {
					dispatch(authFail({ error: "*Usuario/Contraseña incorrectas" }));

					//this.setState({ error: "Usuario/Contraseña incorrectas" });
				}
			})
			.catch(error => {});
	};
};


export const register = (nombre, apellido, mail, unidad) => {
	return dispatch => {
	  //dispatch(authStart());
	  return axios
		.post("http://138.197.231.38/login/register", {
		  nombre: nombre,
		  apellido: apellido,
		  mail: mail
		})
		.then(response => {
		  console.log(response);
		  if (response.data.data.affectedRows == 1) {
			dispatch(registerSuccess(response.data.data.insertId));
		  } else {
			dispatch(authFail({ error: "Usuario/Contraseña incorrectas" }));
  
			//this.setState({ error: "Usuario/Contraseña incorrectas" });
		  }
		})
		.catch(error => {});
	};
  };
  
  export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const registerSuccess = userId => {
	return {
	  type: REGISTER_SUCCESS,
	  userId: userId
	};
  };