import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import store from "../../store/store";
//import { saveGame } from "../../actions/game";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import socketIOClient from "socket.io-client";

import Button from "@material-ui/core/Button";

import "./GameRun.css";

import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import Sound from "react-sound";
import { width } from "@material-ui/system";
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = createMuiTheme({
    typography: {
      fontFamily: 'Anton, Arial',
    },
    TextField:{
      fontFamily: 'Arial'
    }
  });


var connectionOptions = {
  "force new connection": true,
  reconnectionAttempts: "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
  timeout: 10000, //before connect_error and connect_timeout are emitted.
  transports: ["websocket"]
};
  /*
const socket = socketIOClient("http://190.105.227.203/", connectionOptions);
socket.on("setBloom", bloom => console.log(bloom));
socket.on("register", bloom => console.log(bloom));
*/

class GameRun extends Component {

    constructor(props) {
        super(props);
        this.state = {
          socket: null, 
          pressingRun: false,
          pressingJump: false
        };
      } 

      
  componentDidMount = () => {
    console.log("MONTADO "+this.props.userName);
    var socket = socketIOClient("http://190.105.227.203/", connectionOptions);
        socket.on("register",
          player =>{ 
              console.log("id: "+player.id);
              player.name = this.props.userName;
              player.userId = this.props.userId;
              player.location = this.props.loc;
              this.setState({playerId: player.id});
            this.setState({
                player: player
            });
            this.state.socket.emit("name", this.state.player);
    
          });

          socket.on("win", player => {
            if(this.props.loc == player.location)
            {
                console.log("win: "+ player.id +" - me: "+this.state.playerId);
                var winF = player.id === this.state.playerId;
                this.setState({ finished: true, win: winF });
            }
          });
        this.setState({ socket : socket, finished: false, win: false});
  };

  
  componentWillUnmount() {
    clearInterval(this.interval);
  }


    onRun = () => {
        if(!this.state.pressingRun && !this.state.finished)
        {
            console.log("run")
            this.setState({pressingRun : true});
            document.getElementById("runImage").src="/img/boton1-pressed.png";
            this.state.socket.emit("run", this.state.player);
            setTimeout(() => { 
                if(!this.state.finished)
                {
                    this.setState({pressingRun : false});
                    document.getElementById("runImage").src="/img/boton1.png";
                }
            }, 500);
        }
    }

    onJump = () => {
        if(!this.state.pressingJump && !this.state.finished)
        {
            this.setState({pressingJump : true});

            document.getElementById("jumpImage").src="/img/boton3-pressed.png";
            setTimeout(() => {   
                if(!this.state.finished)
                {
                    this.setState({pressingJump : false});
                    document.getElementById("jumpImage").src="/img/boton3.png";
                }
            }, 500);
            this.state.socket.emit("jump", this.state.player);
        }
    }

    onBrake = () => {
        if(!this.state.pressingJump && !this.state.finished)
        {
            this.setState({pressingJump : true});

            document.getElementById("brakeImage").src="/img/boton2-pressed.png";
            setTimeout(() => {   
                if(!this.state.finished)
                {
                    this.setState({pressingJump : false});
                    document.getElementById("brakeImage").src="/img/boton2.png";
                }
            }, 500);
            this.state.socket.emit("brake", this.state.player);
        }
    }

    render()
    {
        return (
            <ThemeProvider theme={theme}>

            <CssBaseline />
            {this.state.finished == false ?
            <div className="gameMainRun">
            <Typography variant="h4" gutterBottom style={{color:"white", paddingTop:"10px", marginLeft:"12.5%",width:"75%", fontSize:"2rem"}}>
                PRESIONA LOS BOTONES PARA CONTROLAR A TU RAPTOR
            </Typography>
            <Grid spacing={2} style={{marginTop:"0px"}}>
                    <Grid
                        spacing={6}
                        alignItems="flex-end"
                        style={{
                        width: "100%",
                        }}
                    >
                        <Grid
                        item
                        style={{
                            width: "100%"
                        }}
                        >
                     <button onClick={this.onJump} style={{backgroundColor:"transparent",border:"0", outline:"none"}}>
                        <img id="jumpImage" src="/img/boton3.png" style={{width:"160px"}}/>
                    </button>
                    <Typography variant="h4" gutterBottom style={{color:"white", marginTop:"-5px", marginLeft:"12.5%",width:"75%"}}>
                    SALTAR
                    </Typography>
                    </Grid>
                    </Grid>
                    <Grid
                        item
                        style={{
                            width: "45%",
                            display:"inline-block"
                        }}
                        >
                    <button onClick={this.onRun} style={{backgroundColor:"transparent",border:"0", outline:"none"}}>
                        <img id="runImage" src="/img/boton1.png" style={{width:"150px"}}/>
                    </button>
                    <Typography variant="h4" gutterBottom style={{color:"white", marginTop:"-5px", marginLeft:"12.5%",width:"75%"}}>
                    CORRER
                    </Typography>
                    </Grid>
                    </Grid>

            </div>
            :
            <div className="gameMain">
                {this.state.win === true ?
                  <Grid spacing={2} style={{marginTop:"0px"}}>
                  <Grid
                      spacing={6}
                      alignItems="flex-end"
                      style={{
                      width: "100%",
                      }}
                  >
                      <Grid
                      item
                      style={{
                          width: "100%"
                      }}
                      >
                      <img id="jumpImage" src="/img/logonuevo2.png" style={{width:"250px", marginTop:"35px"}}/>
                <Typography variant="h4" gutterBottom style={{color:"white", paddingTop:"10px", marginLeft:"12.5%",width:"75%", fontSize:"4.5rem"}}>
                GANASTE! <br/>
                </Typography>
                <Typography variant="h4" gutterBottom style={{color:"white", paddingTop:"10px", marginLeft:"12.5%",width:"75%", fontSize:"2.0rem"}}>
                TU PREMIO APARECERÁ EN LA PANTALLA <br/>
                </Typography>

                </Grid>
                </Grid>
                </Grid>

                :
                <Grid spacing={2} style={{marginTop:"0px"}}>
                  <Grid
                      spacing={6}
                      alignItems="flex-end"
                      style={{
                      width: "100%",
                      }}
                  >
                      <Grid
                      item
                      style={{
                          width: "100%"
                      }}
                      >
                      <img id="jumpImage" src="/img/logonuevo2.png" style={{width:"250px", marginTop:"35px"}}/>
                                      
                <Typography variant="h4" gutterBottom style={{color:"white", paddingTop:"10px", marginLeft:"12.5%",width:"75%", fontSize:"4.5rem"}}>
                 <br/>
                </Typography>
                <Typography variant="h4" gutterBottom style={{color:"white", paddingTop:"10px", marginLeft:"12.5%",width:"75%", fontSize:"2.0rem"}}>
                MEJOR SUERTE LA PRÓXIMA <br/>
                </Typography>
                

                </Grid>
                </Grid>
                </Grid>
                }
            </div>

            }
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => {
  return {
    isRegistered: state.authReducer.isRegistered,
    idUsuario: state.authReducer.idUsuario
  };
};

export default connect(mapStateToProps)(GameRun);
