import React, { Component } from "react";
import { connect } from "react-redux";
import * as raptorActions from "../../store/actions/Index";
import * as authActions from "../../store/actions/auth";
import * as ActionTypes from "../../store/actions/ActionTypes";
import AppBar from "../../components/AppBar/AppBar";
import Container from "@material-ui/core/Container";
import SideMenu from "../../components/SideMenu/SideMenu";
import Users from "../Users/Users";
import CheckPryze from "../CheckPryze/CheckPryze";
import Admin from "../Admin/Admin";
import Pryzes from "../Pryzes/Pryzes";
import PryzesJackpot from "../PryzesJackpot/PryzesJackpot";

class Manager extends Component {
	state = {
		fieldValues: {
			image: ""
		},
		loading: false,
		decisionModalOpen: false,
		modifyFormModalOpen: false,
		modifyTextFormOpen: false,
		idToDelete: 0,
		idToUpdate: 0,
		deleting: false
	};

	componentDidMount() {
		this.props.onLoadUsers(this.props.token, this.props.country);
		this.props.onLoadAdmin(this.props.token);
		this.props.onLoadSucursales(this.props.token);
		this.props.onLoadPryzesGame(this.props.token, this.props.country);
		this.props.onLoadPryzesGameJackpot(this.props.token, this.props.country);

		this.props.onLoadPryzesDelyver(this.props.token, this.props.country);
		
	}

	handleSideMenu = () => {
		this.setState({ sideMenuOpen: !this.state.sideMenuOpen });
	};

	render() {
		const containerStyle = {
			marginTop: "50px"
		};

		let checkPryze = this.props.checkPryzeMenuOpen ? (
			<CheckPryze
				token={this.props.token}
				checkPryze={this.props.checkPryze}
				userPryzes={this.props.pryzes}
				canjearPryze={this.props.canjearPryze}
				idAdminUser={this.props.idAdminUser}
			/>
		) : null;
		let users = this.props.usuariosMenuOpen ? (
			<Users
				users={this.props.usersList}
				token={this.props.token}
			/>
		) : null;
		let admin = this.props.adminMenuOpen ? (
			<Admin
					adminUsers={this.props.adminUsers}
					createUserAdmin={this.props.createUserAdmin}
					token={this.props.token}
					deleteUserAdmin={this.props.deleteUserAdmin}
					sucursales={this.props.sucursales}
			/>
		):null;

		let pryzesGame = this.props.pryzeMenuOpen ? (
			<Pryzes 
				pryzesGameList={this.props.pryzesGameList}
				pryzesDelyver={this.props.pryzesGameDelyver}
				token={this.props.token}
				editPryze={this.props.onEditPryze}
				country={this.props.country}
			/>
		):null;

		let pryzesJackpot = this.props.pryzeJackpotMenuOpen ? (
			<PryzesJackpot
				pryzesGameList={this.props.pryzesJackpot}
				pryzesDelyver={this.props.pryzesGameDelyver}
				token={this.props.token}
				editPryze={this.props.onEditPryze}
				country={this.props.country}
			/>
		):null;

		return (
			<div>
				<AppBar
					openMenu={this.props.handleOpenSideMenu}
					onClickLogout={this.props.handleLogout}

				/>
				{this.props.superAdmin == 1 ? 
				<SideMenu
					open={this.props.sideMenuOpen}
					handleOpenUsuarios={this.props.handleOpenUsuarios}
					handleOpenCheck={this.props.handleOpenCheck}
					handleOpenPryzes={this.props.handleOpenPryzes}
					handleOpenPryzesJackpot={this.props.handleOpenPryzesJackpot}
					handleOpenAdmin={this.props.handleOpenAdmin}
					superAdmin={this.props.superAdmin}
				/>
				:null}

				<Container fixed style={containerStyle}>
					{this.props.superAdmin == 1 ? 
					<React.Fragment>
						{users}
						{checkPryze}
						{admin}
						{pryzesGame}
						{pryzesJackpot}
						</React.Fragment>
					:<React.Fragment>{users}</React.Fragment>}
				</Container>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		sideMenuOpen: state.reducer.sideMenuOpen,
		usuariosMenuOpen: state.reducer.usuariosMenuOpen,
		checkPryzeMenuOpen: state.reducer.checkPryzeMenuOpen,
		token: state.authReducer.token,
		usersList: state.reducer.users,
		pryzes: state.reducer.pryzes,
		pryzesGameList: state.reducer.pryzesGameList,
		pryzesGameDelyver: state.reducer.pryzesGameDelyver,
		pryzesJackpot: state.reducer.pryzesJackpot,
		pryzesDelyver: state.reducer.pryzesDelyver,
		adminMenuOpen: state.reducer.adminMenuOpen,
		pryzeMenuOpen: state.reducer.pryzeMenuOpen,
		checkMenuOpen: state.reducer.checkMenuOpen,
		pryzeJackpotMenuOpen: state.reducer.pryzeJackpotMenuOpen,
		adminUsers: state.reducer.adminUsers,
		superAdmin: state.authReducer.superAdmin,
		idAdminUser: state.authReducer.idAdminUser,
		sucursales: state.reducer.sucursales,
		country: state.authReducer.country
	};
};

const mapDispatchToProps = dispatch => {
	return {

		onLoadUsers: (token, country) => dispatch(raptorActions.loadUsers(token, country)),
		onLoadAdmin: token => dispatch(raptorActions.loadAdmin(token)),
		onLoadSucursales: token => dispatch(raptorActions.loadSucursales(token)),
		onLoadPryzesGame: (token, country) => dispatch(raptorActions.loadPryzesGame(token, country)),
		onLoadPryzesGameJackpot: (token, country) => dispatch(raptorActions.loadPryzesGameJackpot(token, country)),

		onLoadPryzesDelyver: (token, country) => dispatch(raptorActions.loadPryzesDelyver(token, country)),
		onEditPryze: (token, pryze, country) => dispatch(raptorActions.editPryze(token, pryze, country)),
		handleOpenSideMenu: () =>
			dispatch({ type: ActionTypes.OPENCLOSE_SIDEMENU }),
		handleOpenUsuarios: () => dispatch({ type: ActionTypes.OPEN_USUARIOS }),
		handleOpenPryzes: () => dispatch({ type: ActionTypes.OPEN_PRYZES }),
		handleOpenPryzesJackpot: () => dispatch({ type: ActionTypes.OPEN_PRYZES_JACKPOT }),

		handleOpenCheck: () => dispatch({ type: ActionTypes.OPEN_CHECK }),
		handleOpenAdmin: () => dispatch({ type: ActionTypes.OPEN_ADMIN }),
		handleLogout: () => dispatch(authActions.authLogOut()),
		checkPryze: (dpi, token) => dispatch(raptorActions.checkPryze(dpi, token)),
		canjearPryze: (idplay, dpi , token, idAdminUser ) => dispatch(raptorActions.canjearPryze(idplay, dpi, token, idAdminUser)),
		createUserAdmin: (token, userAdmin) => dispatch(raptorActions.createAdmin(token, userAdmin)),
		deleteUserAdmin: (token, idUserAdmin) => dispatch(raptorActions.deleteAdmin(token, idUserAdmin)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Manager);
