import React from "react";
import {Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import Backend from './containers/Backend/Backend';
import RegisterGame from './containers/RegisterGame/RegisterGame';
import RegisterGameRun from './containers/RegisterGameRun/RegisterGameRun';
import RegisterGameSolo from './containers/RegisterGameSolo/RegisterGameSolo';
import RegisterGameSoloHon from './containers/RegisterGameSoloHon/RegisterGameSoloHon';
import RegisterGameSoloPan from './containers/RegisterGameSoloPan/RegisterGameSoloPan';
import RegisterGameSlot from './containers/RegisterGameSlot/RegisterGameSlot';
import RegisterGameSoloGt from './containers/RegisterGameSoloGt/RegisterGameSoloGt';

function App() {
  return (
    <div className="App">
			<Switch>
          <Route exact path="/" component={Backend} />
          <Route path="/game/:loc" component={RegisterGame} />
          <Route path="/gameslot/:loc" component={RegisterGameSlot} />
          <Route path="/gamerun/:loc" component={RegisterGameRun} />
          <Route path="/gamesolohon/:loc" component={RegisterGameSoloHon} />
          <Route path="/gamesolopan/:loc" component={RegisterGameSoloPan} />
          <Route path="/gamesolo/:loc" component={RegisterGameSolo} />
          <Route path="/gamesologt/:loc" component={RegisterGameSoloGt} />

      </Switch>
    </div>
  );
}

export default App;