import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import store from "../../store/store";
//import { saveGame } from "../../actions/game";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import socketIOClient from "socket.io-client";
import { finishGame } from "../../store/actions/UsersAction";

import Button from "@material-ui/core/Button";

import "./Roulette.css";

import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import Sound from "react-sound";
import { width } from "@material-ui/system";
import CssBaseline from '@material-ui/core/CssBaseline';
import { red } from "@material-ui/core/colors";
import Roulette from 'react-roulette-game';


import highlight_img from './img/hightlight.png'
import pointer_img from './img/pointer.png'
import roulette_img_under_highlight from './img/rou_under_high_2.png'
import roulette_img_on_highlight from './img/rou_on_high.png'

const theme = createMuiTheme({
    typography: {
      fontFamily: 'Anton, Arial',
    },
    TextField:{
      fontFamily: 'Arial'
    }
  });

  
const ColorButton = withStyles(theme => ({
    root: {
      fontFamily: "Anton",
      borderColor: red[500],
      backgroundColor: red[500],
      color: theme.palette.getContrastText(red[50]),
      color: red[50],
      borderRadius: 20,
      "&:hover": {
        backgroundColor: red[100],
        color: theme.palette.getContrastText(red[500]),
        color: red[700]
      }
    }
  }))(Button);
  

var connectionOptions = {
  "force new connection": true,
  reconnectionAttempts: "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
  timeout: 10000, //before connect_error and connect_timeout are emitted.
  transports: ["websocket"]
};


  

class RouletteGame extends Component {

    constructor(props) {
        super(props);
        this.state = {
          finished: false,
          socket: null, 
          pressingRun: false,
          pressingJump: false,
          currentButton: -1,
          gameFinished: true,
          points: 0,
          time: 0,
          roulette_props : {
            roulette_img_under_highlight,
            roulette_img_on_highlight,
            highlight_img,
            pointer_img,
            prize_arr: [
              'Producto',
              'Alcohol en Gel',
              'Mascarilla',
              'Chumpa',
              'Gorra',
              'Mangas',
              'Mochila',
              'Camisa'
            ],
            has_reset: false,
            start_text: "JUGAR"
          }
        };
      } 

      
  componentDidMount = () => {
    store.dispatch(
      finishGame(
          this.props.userId,
          2,
          this.props.loc
      )
    );
  };


  runGame = () => {
    this.setState({
        started: true
    });

    this.setState({ gameFinished: false }, () => {
        console.log("RUNGAME "+this.state.gameFinished);
        this.blinkButton();
      }); 
    }

  
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onComplete = (pryze_num) =>{
    console.log("AAAA "+pryze_num);
    setTimeout(() => { 
      this.setState({ finished : true });
    },1000);
  }
  
    render()
    {
        return (
            <ThemeProvider theme={theme}>

            <CssBaseline />
            <div className="gameMain" 
            style={{ backgroundImage: "url(/img/background-"+this.props.background+".png)",
                  backgroundPosition: 'center',
                  backgroundSize: 'center',
                  backgroundRepeat: 'no-repeat'
            }} >
            {
          this.props.background == "gt" ?
            <img src="/img/logo-gt.png" style={{width:"75%", marginTop:"-350px"}}/>
            :
            <img src="/img/logo-.png" style={{width:"75%", marginTop:"-350px"}}/>
        }
            {
                !this.state.finished ? 
                
                <Grid
                  item
                  style={{
                    marginLeft: "10%",
                    marginTop: "0px",
                    width: "80%",
                    height: "100%"
                  }}
                >
                  {this.props.pryze ?
                     <Roulette {...this.state.roulette_props} pryze_num={this.props.pryze ? this.props.pryze.data.roulette : -1 } on_complete={this.onComplete}/> 
                    :                     <Typography variant="h5" gutterBottom style={{backgroundColor: "white", color: red[500], marginTop:"15px", marginLeft:"2.5%",width:"95%"}}>
                    CARGANDO...
                </Typography>

                    }
                     </Grid>
            :
            <div>
            <Grid spacing={2} style={{marginTop:"0px"}}>
                    <Grid
                        spacing={6}
                        style={{
                        width: "100%",
                        }}
                    >
                        <Grid
                        item
                        style={{
                            width: "100%",
                            marginTop: "25px"
                        }}
                        >
                    <Typography variant="h5" gutterBottom style={{color:"white", marginTop:"15px", marginLeft:"2.5%",width:"95%"}}>
                        GANASTE:
                    </Typography>
                    <Typography variant="h4" gutterBottom style={{color:"black", border:"solid 5px #c12121", backgroundColor:"#c3c3c3cf", marginTop:"5px", marginLeft:"25%",width:"50%", paddingTop:"10px", paddingBottom:"10px"}}>
                    {this.props.pryze ? this.props.pryze.data.name : ""}
                    </Typography>
                    <img id="semaforo" src={this.props.pryze.data.image} style={{maxWidth:"200px", maxHeight: "150px", marginTop:"0px"}}/>

                        </Grid>
                    </Grid>
                </Grid>

            </div>
            }
            </div>
           
            
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => {
  return {
    isRegistered: state.authReducer.isRegistered,
    idUsuario: state.authReducer.idUsuario,
    pryze: state.reducer.pryze
  };
};

export default connect(mapStateToProps)(RouletteGame);
