import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	title: {
		flexGrow: 1
	}
}));

export default function Bar(props) {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<AppBar position="static" style={{ backgroundColor: "#000000" }}>
				<Toolbar>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="menu"
						onClick={props.openMenu}
					>
						<MenuIcon />
					</IconButton>
					<img
						src="img/icon.png"
						style={{ height: "70px", marginLeft: "30%" }}
					/>
					<Typography
						variant="h6"
						className={classes.title}
						style={{
							width: "200px",
							marginLeft: "-35%"
						}}
					>
						Raptor App Premios
					</Typography>

					<Button onClick={props.onClickLogout} color="inherit">
						Logout
					</Button>
				</Toolbar>
			</AppBar>
		</div>
	);
}
