import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import blue from "@material-ui/core/colors/indigo";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		maxWidth: 300,
		backgroundColor: "#7C9EB2",
		color: "#ffffff",
		position: "absolute",
		zIndex: 2
	}
}));

export default function SimpleList(props) {
	const classes = useStyles();

	return props.open ? (
		<div className={classes.root}>
			<List
				component="nav"
				aria-label=""
				style={{ backgroundColor: "#7C9EB2" }}
			>
				
				<Divider />
				<ListItem button>
					<ListItemText
						onClick={props.handleOpenUsuarios}
						primary="Usuarios"
					/>
				</ListItem>
				<Divider />
				<ListItem button>
					<ListItemText
						onClick={props.handleOpenPryzes}
						primary="Premios"
					/>
				</ListItem>
				<Divider />
				<ListItem button>
					<ListItemText
						onClick={props.handleOpenPryzesJackpot}
						primary="Premios Ruleta"
					/>
				</ListItem>
				<Divider />
				
			</List>
			<Divider />
		</div>
	) : null;
}
