import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import store from "../../store/store";
//import { saveGame } from "../../actions/game";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import socketIOClient from "socket.io-client";
import { finishGame } from "../../store/actions/UsersAction";

import Button from "@material-ui/core/Button";

import "./GameSolo.css";

import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import Sound from "react-sound";
import { width } from "@material-ui/system";
import CssBaseline from '@material-ui/core/CssBaseline';
import { red } from "@material-ui/core/colors";

const theme = createMuiTheme({
    typography: {
      fontFamily: 'Anton, Arial',
    },
    TextField:{
      fontFamily: 'Arial'
    }
  });

  
const ColorButton = withStyles(theme => ({
    root: {
      fontFamily: "Anton",
      borderColor: red[500],
      backgroundColor: red[500],
      color: theme.palette.getContrastText(red[50]),
      color: red[50],
      borderRadius: 20,
      "&:hover": {
        backgroundColor: red[100],
        color: theme.palette.getContrastText(red[500]),
        color: red[700]
      }
    }
  }))(Button);
  

var connectionOptions = {
  "force new connection": true,
  reconnectionAttempts: "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
  timeout: 10000, //before connect_error and connect_timeout are emitted.
  transports: ["websocket"]
};

class GameSolo extends Component {

    constructor(props) {
        super(props);
        this.state = {
          finished: false,
          socket: null, 
          pressingRun: false,
          pressingJump: false,
          currentButton: -1,
          gameFinished: true,
          points: 0,
          time: 0
        };
      } 

  componentDidMount = () => {

  };

  buttonPressed = (i) => {

    if(i=== this.state.currentButton)
    {
        var buttonImage = "button"+i;
        document.getElementById(buttonImage).src="/img/boton-pressed.png";
        var points = this.state.points;
        this.setState({points: points + 1});
        console.log(this.state.points);
        if(this.state.points > 10)
        {
            this.setState(
                {
                    gameFinished: true,
                }, () => {
            var range = 1;
            document.getElementById("semaforo").src="/img/RedSemaforo.png";

            if(this.state.time == 10)
            {
                document.getElementById("semaforo").src="/img/GreenSemaforo.png";
                range = 3;
            }else if(this.state.time <= 15)
            {
                document.getElementById("semaforo").src="/img/YellowSemaforo.png";
                range = 2;
            }

            this.setState({pryzeRange: range} );
            
            store.dispatch(
                finishGame(
                    this.props.userId,
                    range,
                    this.props.loc
                )
              );
        });

        }else{
            if(this.state.points < 2)
            {
                document.getElementById("bulbImage").src="/img/bulb-2.png";

            }else if(this.state.points < 5)
            {
                document.getElementById("bulbImage").src="/img/bulb-3.png";

            }else if(this.state.points < 7)
            {
                document.getElementById("bulbImage").src="/img/bulb-4.png";

            }else if(this.state.points < 9)
            {
                document.getElementById("bulbImage").src="/img/bulb-5.png";

            }else if(this.state.points <= 10)
            {
                document.getElementById("bulbImage").src="/img/bulb-6.png";
            }
        }
    }

  }

  runGame = () => {
    this.setState({
        started: true
    });

    this.setState({ gameFinished: false }, () => {
        console.log("RUNGAME "+this.state.gameFinished);
        this.blinkButton();
      }); 
    }

    blinkButton = () => {

        setTimeout(() => { 
            if(!this.state.gameFinished)
            {
                if(this.state.currentButton !== -1)
                {
                    var buttonImage = "button"+this.state.currentButton;
                    var b = document.getElementById(buttonImage);
                    if(b){
                        b.src="/img/boton"+this.state.currentButton+".png";
                    }
                }
                var current = this.randomInt(0,4);
                console.log("current: "+current);
                buttonImage = "button"+current;
                this.setState({currentButton : current});
                var b = document.getElementById(buttonImage);
                if(b){
                    b.src="/img/boton"+this.state.currentButton+"-pressed.png";
                }
                this.blinkButton();
                var t = this.state.time + 1;
                this.setState({time: t})
            }
        },1000);
    }

    randomInt = (min, max) => {
	    return min + Math.floor((max - min) * Math.random());
    }


  componentWillUnmount() {
    clearInterval(this.interval);
  }


    render()
    {
        return (
            <ThemeProvider theme={theme}>

            <CssBaseline />
            <div className="gameMainSolo2">
            {
                !this.state.started ?
                <Grid
                  item
                  style={{
                    width: "100%",
                    height: "100%"
                  }}
                >
                    <img id="bulbImage1" src="/img/bulb-1.png" style={{width:"200px"}}/>

                    <Typography variant="h5" gutterBottom style={{color:"white", marginTop:"-35px", marginLeft:"2.5%",width:"95%"}}>
                PRESIONA LOS BOTONES QUE SE ILUMINAN PARA ENCENDER TU ENERGIA
                    </Typography>
                  <ColorButton
                    label="Submit"
                    variant="contained"
                    onClick={this.runGame}
                    style={{
                      height: "50px",
                      marginTop: "80px",
                      width:"80%",
                      fontSize: "2em",
                      alignContent: "center"
                    }}
                  >
                    COMENZAR
                  </ColorButton>
            </Grid>
            :
            !this.state.gameFinished ?
            <Grid spacing={2} style={{marginTop:"0px"}}>
                    <Grid
                        spacing={6}
                        style={{
                        width: "100%",
                        }}
                    >
                        <Grid
                        item
                        style={{
                            width: "100%"
                        }}
                        >
                
                    <button onClick={this.onRun} style={{backgroundColor:"transparent",border:"0", outline:"none"}}>
                        <img id="bulbImage" src="/img/bulb-1.png" style={{width:"170px"}}/>
                    </button>
                    
                    <Typography variant="h5" gutterBottom style={{color:"white", marginTop:"-35px", marginLeft:"2.5%",width:"95%"}}>
                    PRESIONA LOS BOTONES QUE SE ILUMINAN PARA ENCENDER TU ENERGIA
                    </Typography>
                    <Typography variant="h5" gutterBottom style={{color:"white", marginTop:"5px", marginLeft:"2.5%",width:"95%"}}>
                    {this.state.time}
                    </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        spacing={6}
                        alignItems="flex-end"
                        style={{
                        width: "100%",
                        }}
                    >
                        <Grid
                        item
                        style={{
                            marginTop:"-25px",
                            paddingBottom:"50px",
                            width: "100%"
                        }}
                        >
                            <button onClick={()=> this.buttonPressed(0)}  style={{backgroundColor:"transparent",border:"0", outline:"none"}}>
                                <img id="button0" src="/img/boton0.png" style={{width:"150px"}}/>
                                
                            </button>
                            <button onClick={()=> this.buttonPressed(1)}  style={{backgroundColor:"transparent",border:"0", outline:"none"}}>
                                <img id="button1" src="/img/boton1.png" style={{width:"150px"}}/>
                            </button>
                        </Grid>

                      
                        <Grid
                        item
                        style={{
                            marginTop:"-55px",
                            paddingBottom:"50px",
                            width: "100%"
                        }}
                        >
                            <button onClick={()=> this.buttonPressed(2)}  style={{backgroundColor:"transparent",border:"0", outline:"none"}}>
                                <img id="button2" src="/img/boton2.png" style={{width:"150px"}}/>
                            </button>
                            <button onClick={()=> this.buttonPressed(3)} style={{backgroundColor:"transparent",border:"0", outline:"none"}}>
                                <img id="button3" src="/img/boton3.png" style={{width:"150px"}}/>
                            </button>
                    <Typography variant="h4" gutterBottom style={{color:"white", marginTop:"-15px", marginLeft:"12.5%",width:"75%"}}>
                    
                    </Typography>

                        </Grid>
                    </Grid>
                </Grid>
            :
            <div>
            <Grid spacing={2} style={{marginTop:"0px"}}>
                    <Grid
                        spacing={6}
                        style={{
                        width: "100%",
                        }}
                    >
                        <Grid
                        item
                        style={{
                            width: "100%"
                        }}
                        >
                    <button onClick={this.onRun} style={{backgroundColor:"transparent",border:"0", outline:"none"}}>
                        <img id="ss"  src={this.props.pryze ? this.props.pryze.data.image : ""}  style={{width:"170px", marginTop:"25px"}}/>
                    </button>
                    
                    <Typography variant="h5" gutterBottom style={{color:"white", marginTop:"25px", marginLeft:"2.5%",width:"95%"}}>
                        JUEGO TERMINADO! GANASTE:
                    </Typography>
                   
                    <Typography variant="h4" gutterBottom style={{color:"white", marginTop:"5px", marginLeft:"12.5%",width:"75%"}}>
                    {this.props.pryze ? this.props.pryze.data.name : ""}
                    </Typography>
                    <img id="semaforo" src="/img/boton1.png"  style={{width:"350px", marginTop:"-70px"}}/>

                        </Grid>
                    </Grid>
                </Grid>

            </div>
            }
            </div>
           
            
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => {
  return {
    isRegistered: state.authReducer.isRegistered,
    idUsuario: state.authReducer.idUsuario,
    pryze: state.reducer.pryze
  };
};

export default connect(mapStateToProps)(GameSolo);
