import React, { useState, Component } from "react";
import { connect } from "react-redux";
import store from "../../store/store";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import classes from "./RegisterSlot.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { registerUser, authUserCheckState } from "../../store/actions/UsersAction";

import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import { red } from "@material-ui/core/colors";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';



const theme = createMuiTheme({
  typography: {
    fontFamily: 'Montserrat, Arial',
  },
  TextField:{
    fontFamily: 'Montserrat, Arial'
  }
});

const ColorButton = withStyles(theme => ({
  root: {
    fontFamily: "Montserrat",
    borderColor: red[500],
    backgroundColor: red[500],
    color: theme.palette.getContrastText(red[50]),
    color: red[50],
    borderRadius: 20,
    "&:hover": {
      backgroundColor: red[100],
      color: theme.palette.getContrastText(red[500]),
      color: red[700]
    }
  }
}))(Button);

let currentValue = 0;

class RegisterSlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRegistered: false,
      nombre: "",
      apellido: "",
      mail: ""
        };
  }

  componentDidMount() {
    store.dispatch(authUserCheckState());
    this.state = {
      isRegistered: store.getState().authReducer.isRegistered,
      error: store.getState().authReducer.error,

      nombre: "",
      apellido: "",
      mail: "",
      error: null,
      acepto: false
    };

    var unsubscribe = store.subscribe(this.handleChange);
    unsubscribe();
  }


  handleChange = prop => event => {
    this.state = { ...this.state, [prop]: event.target.value };
  };

  submitHandler = () => {
    if (
      this.state.nombre === "" ||
      this.state.apellido === "" ||
      this.state.telefono === "" ||
      this.state.dpi === "" ||
      this.state.acepto === false
    ) {
      alert("Complete todos los campos");
      this.state = { ...this.state, error: "Complete todos los campos" };
    } else {
      console.log(isNaN(this.state.telefono));
      console.log(this.state.telefono.length);

      if(isNaN(this.state.telefono) || this.state.telefono.length != 8)
      {
        alert("Telefono inválido");
        return;
      }
      console.log(isNaN(this.state.dpi));
      console.log(this.state.dpi.length);

      if(isNaN(this.state.dpi) || this.state.dpi.length != 13)
      {
        alert("DPI inválido");
        return;
      }

      this.state = { ...this.state, error: null };

      store.dispatch(
        registerUser(
            "nomail",
            this.state.nombre,
            this.state.apellido,
            this.state.telefono,
            this.state.dpi,
            1
        )
      );
    }
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
      <CssBaseline />

      {this.props.error == undefined ?

      <div className="gameRegisterMainSlot">
        
            <Typography variant="h5" gutterBottom style={{color:"black", marginTop:"300px", marginLeft:"12.5%",width:"75%"}}>
            Completa con tus datos para participar:
            </Typography>

          <FormControl
            style={{
              width: "80%",
              marginTop: "25px",
              marginBottom: "5px"
            }}
          >
            <Grid spacing={8}>

              <Grid
                spacing={8}
                alignItems="flex-end"
                style={{
                  width: "100%",
                  marginLeft:"5%"

                }}
              >
                <Grid
                  item
                  style={{
                    width: "90%"
                  }}
                >
                  <TextField
                    id="input-with-icon-grid"
                    required
                    label="Nombre"
                    onChange={this.handleChange("nombre")}
                    style={{
                      width: "100%",
                      backgroundColor: "white"
                    }}
                    variant="filled"

                  />
                </Grid>
              </Grid>
              <br/>
              <Grid
                spacing={8}
                alignItems="flex-end"
                style={{
                  width: "100%",
                  marginLeft:"5%"

                }}
              >
                <Grid
                  item
                  style={{
                    width: "90%"
                  }}
                >
                  <TextField
                    id="input-with-icon-grid"
                    required
                    label="Apellido"
                    onChange={this.handleChange("apellido")}
                    style={{
                      width: "100%",
                      backgroundColor: "white"
                    }}
                    variant="filled"

                  />
                </Grid>
              </Grid>
              <Grid
                spacing={8}
                alignItems="flex-end"
                style={{
                  width: "100%",
                  marginTop:"25px",
                  marginLeft:"5%"

                }}
              >
                <Grid
                  item
                  style={{
                    width: "90%"
                  }}
                >
                  <TextField
                    required
                    id="input-with-icon-grid"
                    label="Telefono"
                    onChange={this.handleChange("telefono")}
                    style={{
                      width: "100%",
                      backgroundColor: "white"

                    }}
                    variant="filled"

                  />
                </Grid>
              </Grid>              
              
              <Grid
                spacing={8}
                alignItems="flex-end"
                style={{
                  width: "100%",
                  marginTop:"25px",
                  marginLeft:"5%"

                }}
              >
                <Grid
                  item
                  style={{
                    width: "90%"
                  }}
                >
                  <TextField
                    required
                    id="input-with-icon-grid"
                    label={this.props.country == "HN" ? "ID" : "DPI"}
                    onChange={this.handleChange("dpi")}
                    style={{
                      width: "100%",
                      backgroundColor: "white"

                    }}
                    variant="filled"

                  />
                </Grid>
              </Grid>

              <Grid
                spacing={8}
                alignItems="flex-end"
                style={{
                  width: "100%",
                  marginTop:"25px",
                  marginLeft:"5%"

                }}
              >
                <Grid
                  item
                  style={{
                    width: "90%"
                  }}
                >
                  <FormControlLabel
        control={<Checkbox checked={this.state.acepto} onChange={this.handleChange("acepto")} name="checkedA" style={{color:"black"}}/>}
        label="ACEPTO TERMINOS Y CONDICIONES"
        style={{color:"black"}}
      />

                </Grid>
              </Grid>


              {this.state.error !== null ? (
                <Grid
                  container
                  spacing={8}
                  alignItems="flex-end"
                  style={{ marginTop: "10px" }}
                >
                  <Typography variant="h7">{this.state.error}</Typography>
                </Grid>
              ) : null}
                 <Grid
                spacing={8}
                alignItems="flex-end"
                style={{
                  width: "100%",
                  marginTop:"25px",
                  marginLeft:"5%"

                }}
              >
               <Grid
                  item
                  style={{
                    width: "90%"
                  }}
                >
                  <ColorButton
                    label="Submit"
                    variant="contained"
                    onClick={this.submitHandler}
                    style={{
                      height: "50px",
                      marginTop: "0px",
                      width:"80%",
                      fontSize: "1.5em",
                      alignContent: "center"
                    }}
                  >
                    REGISTRARSE
                  </ColorButton>
                </Grid>

              </Grid>

              <Grid
                spacing={8}
                alignItems="flex-end"
                style={{
                  width: "100%",
                  marginTop:"25px",
                  marginLeft:"5%"

                }}
              >
                <Grid
                  item
                  style={{
                    width: "90%"
                  }}
                >
                  <Typography
        style={{color:"black"}}
      >
TERMINOS Y CONDICIONES
        </Typography>

                </Grid>
              </Grid>
            </Grid>

          </FormControl>
      </div>
  :<div className="gameRegisterMain">
    <img src="/img/logonuevo.png" style={{width:"365px"}}/>
    <Typography variant="h4" gutterBottom style={{color:"white", marginTop:"20px", marginLeft:"12.5%",width:"75%"}}>
    YA HAS PARTICIPADO EL DIA DE HOY EN OTRA ACTIVACION,<br/> 
    <br/>  TE ESPERAMOS MAÑANA
    </Typography>
  </div>
}

       </ThemeProvider>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.reducer.error
  };
};

export default connect(mapStateToProps)(RegisterSlot);
