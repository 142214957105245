import { AgGridReact, AgGridColumn } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import XLSX from "xlsx";
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';

import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";

import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';


class Users extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			current:"general",
			username: "",
			userid: "",
			userlastname:"",
			useremail:"",
			userage:"",
			userpassword:"",
			
		};
	}

	
	onRowSelected = event => {
		console.log(event);
		
		if (event.node.selected) {
			console.log(event.data);
			this.setState({
				rowSelected: true, 
				selectedUser: event.data.iduser
				});

		}else{
			if(this.state.selectedUser == event.data.iduser)
			{
			this.setState({
				rowSelected: false,
				selectedUser: null
				});
			}
		}
	  };



	exportFile = () => {
		/* convert state to workbook */
		let cols = this.props.users.map((info, i) => {
			return [
				info.email,
				info.username,
				info.lastname,
				info.dni,
				info.phone, 
				info.date,
				info.idregion,
				info.pryzename, 
				info.description
			];
		});
		let hcols = [
			"E-mail",
			"Nombre",
			"Apellido",
			"DPI",
			"Telefono",
			"Fecha",
			"País",
			"Premio",
			"Descripción"
		];

		const exp = [[...hcols], ...cols];
		const ws = XLSX.utils.aoa_to_sheet(exp);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Usuarios");
		/* generate XLSX file and send to client */
		XLSX.writeFile(wb, "Usuarios.xlsx");
	};

	handleDelete = () => {
		this.props.deleteUser(this.props.token, this.state.selectedUser);
		this.setState({
			deleteModal:false
		});
	}


	componentDidMount() {

	}

	render() {
		return (
			<React.Fragment>
				<div style={{ width: "100%", textAlign: "left", marginBottom: "20px" }}>

				<h2 style={{ width: "60%", textAlign: "left" }}>Usuarios Participantes</h2>
					<div >

					<Button
							onClick={this.exportFile}
							color="primary"
							variant="contained"
						>
							Exportar a Excel
						</Button>
					</div>
				</div>
				<div
					className="ag-theme-balham"
					style={{ height: "80vh", width: "100%" }}
				>
					<AgGridReact
						enableSorting={true}
						enableFilter={true}
						columnDefs={this.state.columnDefs}
						rowData={this.props.users  ? this.props.users : null}
						rowSelection="single"
						onRowSelected={this.onRowSelected}
						onSelectionChanged={this.onSelectionChanged}
						pagination={true}
            			paginationAutoPageSize={true}
					>
						<AgGridColumn headerName="Email" field="email" width={200} />
						<AgGridColumn headerName="Nombre" field="username" width={200} />
						<AgGridColumn headerName="Apellido" field="lastname" width={200} />
						<AgGridColumn headerName="DPI" field="dni" width={130} />
						<AgGridColumn headerName="Phone" field="phone" width={100} />
						<AgGridColumn headerName="Fecha" field="date" width={200} />
						<AgGridColumn headerName="País" field="idregion" width={100} />
						<AgGridColumn headerName="Premio" field="pryzename" width={100} />
						<AgGridColumn headerName="Descripcion" field="description" width={700} />
						<AgGridColumn headerName="Id" field="idplay" width={20} />

					</AgGridReact>
				</div>
			</React.Fragment>
		);
	}
}

export default Users;
