export const OPENCLOSE_SIDEMENU = "OPENCLOSE_SIDEMENU";
export const OPEN_FORMMENU = "OPEN_FORMMENU";
export const OPEN_USUARIOS = "OPEN_USUARIOS";
export const OPEN_PRYZES = "OPEN_PRYZES";
export const OPEN_PRYZES_JACKPOT = "OPEN_PRYZES_JACKPOT";

export const OPEN_CHECK = "OPEN_CHECK";
export const OPEN_ADMIN = "OPEN_ADMIN";


export const UPLOADING_IMAGE = "UPLOADING_IMAGE";

export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES";
export const OPEN_ACTIVITIES = "OPEN_ACTIVITIES";

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_ADMIN = "LOAD_ADMIN";


export const LOAD_PRYZES = "LOAD_PRYZES";
export const LOAD_PRYZES_GAME = "LOAD_PRYZES_GAME";


export const LOAD_QUESTIONS = "LOAD_QUESTIONS";
export const LOAD_TOPQUESTIONS = "LOAD_TOPQUESTIONS";

export const LOAD_LEVELS = "LOAD_LEVELS";
export const LOAD_LEVELCATEGORIES = "LOAD_LEVELCATEGORIES";


export const LOAD_FAVOURITES = "LOAD_FAVOURITES";
export const OPEN_FAVOURITES = "OPEN_FAVOURITES";

export const LOAD_PROMOTIONS = "LOAD_PROMOTIONS";

export const OPEN_PROMOTIONS = "OPEN_PROMOTIONS";

export const LOAD_PRINCIPAL = "LOAD_PRINCIPAL";

export const OPEN_PRINCIPAL = "OPEN_PRINCIPAL";

export const LOAD_SUCURSALES = "LOAD_SUCURSALES";
export const SET_PRYZE = "SET_PRYZE";
export const SET_PRYZES_GAME = "SET_PRYZES_GAME";
export const SET_PRYZES_JACKPOT = "SET_PRYZES_JACKPOT";

export const SET_PRYZES_DELYVER = "SET_PRYZES_DELYVER";
export const SET_SUCURSALES = "SET_SUCURSALES";



export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

export const REGISTER_ERROR = "REGISTER_ERROR";


export const REGISTER_USER_LOGOUT = "REGISTER_USER_LOGOUT";

